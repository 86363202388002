import { AbilityBuilder } from "@casl/ability";
import { AppAbility, CRUD } from ".";
import { UserRole } from "../enums/UserRole.enum";
import { INetworkInstance } from "../interfaces/network.interface";
import { IUserInstance } from "../interfaces/user.interface";

export type NetworkSubjects = "Network" | INetworkInstance;

export default function defineNetworkAbility(builder: AbilityBuilder<AppAbility>, user: IUserInstance | null) {
    const { can, cannot, build } = builder;

    if (user) {
        if (user.role === UserRole.SuperAdmin) {
            can([...CRUD, "list", "managePlaylists", "approveContent", "manageServices"], "Network");
        } else {
            can("read", "Network", {
                id: {
                    $in: user.networkIds
                }
            });

            if (user.role === UserRole.NetworkAdmin) {
                can(["manageUsers", "managePlaylists", "approveContent", "manageServices"], "Network", {
                    id: {
                        $in: user.networkIds
                    }
                });
            }
        }
    }

    return builder;
}
