import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AuthGuard } from "src/shared/guards/auth.guard";
import { IRoute } from "src/shared/interfaces/route.interface";

const routes: IRoute[] = [
    {
        path: "",
        redirectTo: "auth",
        pathMatch: "full"
    },
    {
        path: "auth",
        loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule)
    },
    {
        path: "portal",
        loadChildren: () => import("./portal/portal.module").then((m) => m.PortalModule),
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
