import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    async canActivate(route: ActivatedRouteSnapshot) {
        if (!this.authService.isLoggedIn) {
            this.authService.checkAuthState();
        }
        if (this.authService.isLoggedIn) {
            return true;
        } else {
            this.router.navigate(["/auth", "login"]);
            return false;
        }
    }
}
