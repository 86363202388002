import { AbilityBuilder } from "@casl/ability";
import { AppAbility, CRUD } from ".";
import { UserRole } from "../enums/UserRole.enum";
import { INetworkGroupInstance } from "../interfaces/networkGroup.interface";
import { IUserInstance } from "../interfaces/user.interface";

export type NetworkGroupSubjects = "NetworkGroup" | INetworkGroupInstance;

export default function defineNetworkGroupAbility(builder: AbilityBuilder<AppAbility>, user: IUserInstance | null) {
    const { can, cannot, build } = builder;

    if (user) {
        if (user.role === UserRole.SuperAdmin) {
            can([...CRUD, "list", "manageUsers", "manageNetworkGroups", "manageLocations"], "NetworkGroup");
        } else {
            can(["read"], "NetworkGroup", {
                networkId: {
                    $in: user.networkIds
                },
                id: {
                    $in: user.networkGroupIds
                }
            });

            if (user.role === UserRole.NetworkAdmin) {
                can([...CRUD, "manageUsers", "manageNetworkGroups", "manageLocations"], "NetworkGroup", {
                    networkId: {
                        $in: user.networkIds
                    }
                });
            } else if (user.role === UserRole.GroupAdmin) {
                can(["read", "list", "manageUsers", "manageNetworkGroups", "manageLocations"], "NetworkGroup", {
                    networkId: {
                        $in: user.networkIds
                    },
                    id: {
                        $in: user.networkGroupIds
                    }
                });
            }
        }
    }

    return builder;
}
