import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class AppStateService {
    private _isLoading$ = new BehaviorSubject<boolean>(false);

    constructor() {}

    public get isLoading$() {
        return this._isLoading$.pipe(distinctUntilChanged(), debounceTime(250));
    }

    public set isLoading(isLoading: boolean) {
        this._isLoading$.next(isLoading);
    }

    public get isLoading(): boolean {
        return this._isLoading$.getValue();
    }
}
