import { AbilityBuilder } from "@casl/ability";
import { AppAbility, CRUD } from ".";
import { UserRole } from "../enums/UserRole.enum";
import { ILocationInstance } from "../interfaces/location.interface";
import { IUserInstance } from "../interfaces/user.interface";

export type LocationSubjects = "Location" | ILocationInstance;

export default function defineLocationAbility(builder: AbilityBuilder<AppAbility>, user: IUserInstance | null) {
    const { can, cannot } = builder;

    if (user) {
        if (user.role === UserRole.SuperAdmin) {
            can([...CRUD, "list", "manageDevices", "manageUsers", "manageServices", "managePlaylists"], "Location");
        } else {
            can(["read", "list"], "Location", {
                networkId: {
                    $in: user.networkIds
                },
                networkGroupId: {
                    $exists: false
                },
                id: {
                    $in: user.locationIds
                }
            });

            can(["read", "list"], "Location", {
                networkId: {
                    $in: user.networkIds
                },
                networkGroupId: {
                    $exists: true,
                    $in: user.networkGroupIds
                }
            });

            if (user.role === UserRole.NetworkAdmin) {
                can([...CRUD, "list", "manageDevices", "manageServices", "managePlaylists"], "Location", {
                    networkId: {
                        $in: user.networkIds
                    }
                });
            } else if (user.role === UserRole.GroupAdmin) {
                can(["read", "list", "manageDevices", "manageServices", "managePlaylists"], "Location", {
                    networkGroupId: {
                        $exists: true,
                        $in: user.networkGroupIds
                    }
                });
            } else if (user.role === UserRole.LocationAdmin) {
                can(["read", "manageServices", "manageDevices", "managePlaylists"], "Location", {
                    id: {
                        $in: user.locationIds
                    }
                });
            }
        }
    }

    return builder;
}
