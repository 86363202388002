import { AbilityBuilder } from "@casl/ability";
import { AppAbility, CRUD } from ".";
import { FeatureSlug } from "../enums/FeatureSlug.enum";
import { UserRole } from "../enums/UserRole.enum";
import { IFeatureInstance } from "../interfaces/feature.interface";
import { ILocationInstance } from "../interfaces/location.interface";
import { INetworkInstance } from "../interfaces/network.interface";
import { IUserInstance } from "../interfaces/user.interface";

export type FeatureSubjects = "Feature" | IFeatureInstance;

export function defineFeatureAbilityForUser(builder: AbilityBuilder<AppAbility>, user: IUserInstance | null) {
    const { can, cannot, build } = builder;

    if (user) {
        if (user.role === UserRole.SuperAdmin) {
            can([...CRUD, "list"], "Feature");
        }
    }

    return builder;
}

export const knownFeatureSlugs = [...Object.values(FeatureSlug)] as const;

export type FeatureSlugSubjects = typeof knownFeatureSlugs[number];

export function defineFeatureAbilityForLocation(
    builder: AbilityBuilder<AppAbility>,
    location: ILocationInstance | null
) {
    const { can, cannot, build } = builder;

    if (location && location.locationFeatures) {
        const locationFeatures = location.locationFeatures || [];
        knownFeatureSlugs.forEach((slug) => {
            locationFeatures.forEach((feature) => {
                if (feature.slug === slug) {
                    can("showLocationFeature", slug);
                }
            });
        });
    }

    return builder;
}

export function defineFeatureAbilityForNetwork(builder: AbilityBuilder<AppAbility>, network: INetworkInstance | null) {
    const { can, cannot, build } = builder;

    if (network && network.networkFeatures) {
        const networkFeatures = network.networkFeatures || [];
        knownFeatureSlugs.forEach((slug) => {
            networkFeatures.forEach((feature) => {
                if (feature.slug === slug) {
                    can("showNetworkFeature", slug);
                }
            });
        });
    }

    return builder;
}
