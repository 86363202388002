import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { fsGetFileSha1, fsGetFileSpecSize } from "bsn-cloud-core/fsconnector-browser";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class ApiService {
    public baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.baseUrl;
    }

    public get<T>(endpoint: string, options = {}): Observable<T> {
        return this.http.get<T>(`${this.baseUrl}/${endpoint}`, {
            responseType: "json",
            ...options
        });
    }

    public post<T, Y>(endpoint: string, body: {} | T, options = {}): Observable<Y> {
        return this.http.post<Y>(`${this.baseUrl}/${endpoint}`, body, {
            responseType: "json",
            ...options
        });
    }

    public put<T, Y>(endpoint: string, body: Partial<T>, options = {}): Observable<Y> {
        return this.http.put<Y>(`${this.baseUrl}/${endpoint}`, body, {
            responseType: "json",
            ...options
        });
    }

    public patch<T, Y>(endpoint: string, body: Partial<T>, options = {}): Observable<Y> {
        return this.http.patch<Y>(`${this.baseUrl}/${endpoint}`, body, {
            responseType: "json",
            ...options
        });
    }

    public upload<T>(
        endpoint: string,
        file: File,
        options: any = {},
        formId: string
    ): Observable<IUploadStatus<T | null>> {
        const formData = new FormData();
        formData.append(formId, file, file.name);

        return forkJoin([fsGetFileSha1(file), fsGetFileSpecSize(file)]).pipe(
            switchMap(([sha1, fileSize]: [string, number]) => {
                let headers = new HttpHeaders();
                headers = headers.set("file-content-length", fileSize.toString());
                headers = headers.set("file-sha1", sha1);
                return this.http.put<T>(`${this.baseUrl}/${endpoint}`, formData, {
                    responseType: "json",
                    reportProgress: true,
                    observe: "events",
                    ...options,
                    headers
                });
            }),
            map((event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const progress = Math.round((100 * event.loaded) / (event.total || file.size));
                        return {
                            progress,
                            response: null
                        } as IUploadStatus<T | null>;
                    case HttpEventType.Response:
                        return {
                            progress: 100,
                            response: event.body
                        } as IUploadStatus<T>;
                    default:
                        return {
                            progress: 0,
                            response: null
                        };
                }
            }),
            catchError((err) => {
                return of({
                    progress: 0,
                    response: null,
                    error: err
                } as IUploadStatus<null>);
            })
        );
    }

    public delete(endpoint: string, options = {}): Observable<any> {
        return this.http.delete(`${this.baseUrl}/${endpoint}`, {
            responseType: "json",
            ...options
        });
    }
}

export interface IUploadStatus<T> {
    progress: number;
    response: T;
    error?: any;
}
