export enum LocalStorageKeys {
    Token = "snowowl:token",
    User = "snowowl:user",
    Network = "snowowl:network",
    // selected table columns
    NetworksSelectedColumns = "snowowl:networks:selectedcolumns",
    LocationsSelectedColumns = "snowowl:locations:selectedcolumns",
    UsersSelectedColumns = "snowowl:users:selectedcolumns",
    DevicesSelectedColumns = "snowowl:devices:selectedcolumns",
    DeviceHealthSelectedColumns = "snowowl:devicehealth:selectedcolumns",
    FeaturesSelectedColumns = "snowowl:features:selectedcolumns",
    PresentationsSelectedColumns = "snowowl:presentations:selectedcolumns"
}
