import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppAbility, defineAbilityForLocation } from "../abilities";
import { IPaginatedResponse, IPaginationRequest } from "../interfaces/api.interface";
import { IDeviceInstance } from "../interfaces/device.interface";
import { ILocationInstance } from "../interfaces/location.interface";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: "root"
})
export class LocationService {
    private _currentLocation$: BehaviorSubject<ILocationInstance | null>;

    constructor(private api: ApiService, private ability: AppAbility) {
        this._currentLocation$ = new BehaviorSubject<ILocationInstance | null>(null);
    }

    public clearCurrentLocation(): void {
        this._currentLocation$.next(null);
        this.updateLocationAbility(null);
    }

    public get currentLocation$(): Observable<ILocationInstance | null> {
        return this._currentLocation$;
    }

    public setCurrentLocation(location: ILocationInstance | null) {
        this.updateLocationAbility(location);
        this._currentLocation$.next(location);
    }

    private updateLocationAbility(location: ILocationInstance | null) {
        const ability = defineAbilityForLocation(location, this.ability);
        this.ability.update(ability.rules);
    }

    public getLocations(request: IPaginationRequest, query: any) {
        return this.api
            .get<IPaginatedResponse<ILocationInstance>>("locations", {
                params: { ...request, ...query }
            })
            .pipe(
                catchError((err) => {
                    return of({
                        data: [],
                        hasMore: false,
                        totalCount: 0
                    });
                })
            );
    }

    public getLocation(locationId: string) {
        return this.api.get<ILocationInstance>(`locations/${locationId}`);
    }

    public createLocation(form: ILocationInstance): Observable<ILocationInstance | null> {
        return this.api.post<ILocationInstance, ILocationInstance>("locations", { location: form });
    }

    public updateLocation(form: ILocationInstance): Observable<ILocationInstance | null> {
        return this.api.post<ILocationInstance, ILocationInstance>(`locations/${form.id}`, {
            location: form
        });
    }

    public deleteLocation(locationId: string): Observable<boolean> {
        return this.api.delete(`locations/${locationId}`);
    }

    public getLocationDevices(locationId: string, request: IPaginationRequest, query: any) {
        return this.api
            .get<IPaginatedResponse<IDeviceInstance>>(`locations/${locationId}/Devices`, {
                params: { ...request, ...query }
            })
            .pipe(
                catchError((err) => {
                    return of({
                        data: [],
                        hasMore: false,
                        totalCount: 0
                    });
                })
            );
    }

    public getLocationData<T>(locationId: string, scope: string, property: string): Observable<T | null> {
        return this.api
            .get<{ data: T | null }>(`locations/${locationId}/data/${scope}/${property}`)
            .pipe(map((res) => res.data));
    }

    public updateLocationData<T>(locationId: string, scope: string, property: string, data: T): Observable<T> {
        return this.api
            .put<{ data: T }, { data: T }>(`locations/${locationId}/data/${scope}/${property}`, {
                data
            })
            .pipe(map((res) => res.data));
    }

    public getLocationAddress(location: ILocationInstance) {
        if (!location?.address) {
            return "";
        }
        const address = location.address;
        if (address) {
            const { streetAddress1, streetAddress2, city, state, postalCode } = address;
            return `${streetAddress1}, ${streetAddress2 ? streetAddress2 : city}, ${
                streetAddress2 ? city + "," : state
            } ${streetAddress2 ? state : postalCode}`;
        } else {
            return "";
        }
    }
}
