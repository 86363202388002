import { Pipe, PipeTransform } from "@angular/core";
import { AppAbility, IAbility, LocationAbilityAction, NetworkAbilityAction } from "../abilities";

@Pipe({
    name: "multiAble",
    pure: false
})
export class MultiAblePipe implements PipeTransform {
    constructor(private ability: AppAbility) {}

    transform(abilities?: IAbility[] | IAbility, any?: boolean): boolean {
        return this.checkAbilities(abilities, any);
    }

    private checkAbilities(abilities?: IAbility[] | IAbility, any?: boolean): boolean {
        if (!abilities) {
            return true;
        }
        if (Array.isArray(abilities)) {
            if (any) {
                return abilities.some((ability) => {
                    if (Array.isArray(ability.action)) {
                        return ability.action.some((action) => this.ability.can(action, ability.subject));
                    } else {
                        return this.ability.can(ability.action, ability.subject);
                    }
                });
            } else {
                return abilities.every((ability) => {
                    if (Array.isArray(ability.action)) {
                        return ability.action.every((action) => this.ability.can(action, ability.subject));
                    } else {
                        return this.ability.can(ability.action, ability.subject);
                    }
                });
            }
        } else {
            if (Array.isArray(abilities.action)) {
                return abilities.action.every((action) => this.ability.can(action, abilities.subject));
            } else {
                return this.ability.can(abilities.action, abilities.subject);
            }
        }
    }

    public filterAbilities<T extends LocationAbilityAction | NetworkAbilityAction>(
        abilities: IAbility[] = [],
        type: readonly T[],
        include: boolean
    ): IAbility[] {
        return abilities.filter((ability) => {
            let match: boolean;
            if (Array.isArray(ability.action)) {
                match = ability.action.every((action) => type.includes(action as T));
            } else {
                match = type.includes(ability.action as T);
            }
            return include ? match : !match;
        });
    }
}
