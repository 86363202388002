import { Ability, AbilityBuilder, AbilityClass, ForbiddenError } from "@casl/ability";
import { ILocationInstance } from "../interfaces/location.interface";
import { INetworkInstance } from "../interfaces/network.interface";
import { IUserInstance } from "../interfaces/user.interface";
import defineContentAbility, { ContentSubjects } from "./content.ability";
import defineDeviceAbility, { DeviceSubjects } from "./devices.ability";
import {
    defineFeatureAbilityForLocation,
    defineFeatureAbilityForNetwork,
    defineFeatureAbilityForUser,
    FeatureSlugSubjects,
    FeatureSubjects
} from "./features.ability";
import defineLocationAbility, { LocationSubjects } from "./locations.ability";
import defineNetworkGroupAbility, { NetworkGroupSubjects } from "./networkgroups.ability";
import defineNetworkAbility, { NetworkSubjects } from "./networks.ability";
import definePlaylistAbility, { PlaylistSubjects } from "./playlists.ability";
import definePresentationAbility, { PresentationSubjects } from "./presentations.ability";
import defineServiceAbility, { ServiceSubjects } from "./services.ability";
import defineUserAbility, { UserSubjects } from "./users.ability";

ForbiddenError.setDefaultMessage((error) => {
    switch (error.action) {
        case "list":
            return `You are not allowed to ${error.action} ${error.subjectType}s`;
        default:
            return `You are not allowed to ${error.action} this ${error.subjectType}`;
    }
});

export const CRUD: UserAbilityAction[] = ["create", "read", "update", "delete"];

export const userAbilityActions = [
    "create",
    "read",
    "update",
    "delete",
    "list",
    "manageUsers",
    "manageDevices",
    "manageServices",
    "manageLocations",
    "managePlaylists",
    "approveContent",
    "manageNetworkGroups",
    "import"
];
export type UserAbilityAction = typeof userAbilityActions[number];

export const locationAbilityActions = ["showLocationFeature"] as const;
export type LocationAbilityAction = typeof locationAbilityActions[number];

export const networkAbilityActions = ["showNetworkFeature"] as const;
export type NetworkAbilityAction = typeof networkAbilityActions[number];

export type AppAbilityAction = UserAbilityAction | LocationAbilityAction | NetworkAbilityAction;

export type Subjects =
    | DeviceSubjects
    | PresentationSubjects
    | UserSubjects
    | LocationSubjects
    | FeatureSubjects
    | ServiceSubjects
    | NetworkSubjects
    | FeatureSlugSubjects
    | PlaylistSubjects
    | ContentSubjects
    | NetworkGroupSubjects;

export interface IAbility {
    subject: Subjects;
    action: AppAbilityAction | AppAbilityAction[];
}

export type AppAbility = Ability<[AppAbilityAction, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export function defineAbilityForUser(user: IUserInstance | null, ability: AppAbility): AppAbility {
    let builder = new AbilityBuilder<AppAbility>(AppAbility);

    builder.rules = ability.rules.filter((rule) => {
        if (Array.isArray(rule.action)) {
            const found: boolean[] = [];
            for (let i = 0; i < rule.action.length; i++) {
                if (userAbilityActions.includes(rule.action[i])) {
                    found.push(true);
                } else {
                    found.push(false);
                }
            }
            return found.includes(false);
        } else {
            return !userAbilityActions.includes(rule.action as UserAbilityAction);
        }
    });

    builder = defineLocationAbility(builder, user);
    builder = defineUserAbility(builder, user);
    builder = defineDeviceAbility(builder, user);
    builder = definePresentationAbility(builder, user);
    builder = defineFeatureAbilityForUser(builder, user);
    builder = defineServiceAbility(builder, user);
    builder = defineNetworkAbility(builder, user);
    builder = defineNetworkGroupAbility(builder, user);
    builder = definePlaylistAbility(builder, user);
    builder = defineContentAbility(builder, user);

    return builder.build();
}

export function defineAbilityForLocation(location: ILocationInstance | null, ability: AppAbility): AppAbility {
    let builder = new AbilityBuilder<AppAbility>(AppAbility);
    builder.rules = ability.rules.filter(
        (rule) => !locationAbilityActions.includes(rule.action as LocationAbilityAction)
    );

    builder = defineFeatureAbilityForLocation(builder, location);

    return builder.build();
}

export function defineAbilityForNetwork(network: INetworkInstance | null, ability: AppAbility): AppAbility {
    let builder = new AbilityBuilder<AppAbility>(AppAbility);
    builder.rules = ability.rules.filter(
        (rule) => !networkAbilityActions.includes(rule.action as NetworkAbilityAction)
    );

    builder = defineFeatureAbilityForNetwork(builder, network);

    return builder.build();
}
