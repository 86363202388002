import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "../services/auth.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private snackBar: MatSnackBar) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                console.error(err);

                if (err.status === 401) {
                    if (this.authService.isLoggedIn && this.authService.checkTokenExpiration()) {
                        this.authService.logout();
                    }
                }

                const message = err.error ? err.error.message : err.message;

                const snackBar = this.snackBar.open(message || "Unknown Error", "Dismiss", {
                    duration: 5000,
                    panelClass: "background-danger"
                });

                return throwError(err);
            })
        );
    }
}
