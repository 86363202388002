import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map, shareReplay, tap } from "rxjs/operators";
import { AppAbility, defineAbilityForNetwork } from "../abilities";
import { LocalStorageKeys } from "../enums/LocalStorageKeys.enum";
import { NetworkContextType } from "../enums/NetworkContextType.enum";
import { IPaginatedResponse, IPaginationRequest } from "../interfaces/api.interface";
import { INetworkContext, INetworkInstance } from "../interfaces/network.interface";
import { INetworkThemeInstance } from "../interfaces/networkTheme.interface";
import { ApiService } from "./api.service";
import { StorageService } from "./storage.service";
import { ThemeService } from "./theme.service";

@Injectable({
    providedIn: "root"
})
export class NetworksService {
    private _defaultNetworkContext: INetworkContext = { contextType: NetworkContextType.None, network: null };
    private _currentNetworkContext$: BehaviorSubject<INetworkContext>;

    constructor(
        private api: ApiService,
        private ability: AppAbility,
        private themeService: ThemeService,
        private storage: StorageService
    ) {
        let context = this._defaultNetworkContext;
        // Initialize network context with network from session storage, if it exists
        const network: INetworkInstance | string | null = this.storage.getSession<INetworkInstance>(
            LocalStorageKeys.Network,
            true
        );

        if (network && !(network instanceof String)) {
            context = {
                contextType: NetworkContextType.Network,
                network: network as INetworkInstance
            };
        }
        this._currentNetworkContext$ = new BehaviorSubject(context);
    }

    public get currentNetworkContext$(): Observable<INetworkContext> {
        return this._currentNetworkContext$.pipe(
            tap((networkContext) => {
                this.updateNetworkAbility(networkContext.network);
                this.themeService.saveTheme(networkContext.network?.networkTheme);
            }),
            shareReplay()
        );
    }

    public get currentNetworkContext(): INetworkContext {
        return this._currentNetworkContext$.getValue();
    }

    public setCurrentNetworkContext(contextType: NetworkContextType, network: INetworkInstance | null = null): void {
        switch (contextType) {
            case NetworkContextType.None:
                this._currentNetworkContext$.next({ contextType, network: null });
                this.storage.removeSession(LocalStorageKeys.Network);
                break;
            case NetworkContextType.Global:
                this._currentNetworkContext$.next({ contextType, network: null });
                this.storage.removeSession(LocalStorageKeys.Network);
                break;
            case NetworkContextType.Network:
                this._currentNetworkContext$.next({
                    contextType,
                    network: network || null
                });
                this.storage.setSession(LocalStorageKeys.Network, network, true);
                break;
            default:
                this._currentNetworkContext$.next({
                    contextType: NetworkContextType.None,
                    network: null
                });
                this.storage.removeSession(LocalStorageKeys.Network);
                break;
        }
    }

    public clearCurrentNetworkContext(): void {
        this._currentNetworkContext$.next({
            contextType: NetworkContextType.None,
            network: null
        });
    }

    public getNetworks(request: IPaginationRequest, query: any) {
        return this.api
            .get<IPaginatedResponse<INetworkInstance>>("networks", {
                params: { ...request, ...query }
            })
            .pipe(
                catchError((err) => {
                    return of({
                        data: [],
                        hasMore: false,
                        totalCount: 0
                    });
                })
            );
    }

    public getNetwork(networkId: string) {
        return this.api.get<INetworkInstance>(`networks/${networkId}`);
    }

    public createNetwork(form: INetworkInstance): Observable<INetworkInstance> {
        return this.api.post<INetworkInstance, INetworkInstance>("networks", { network: form });
    }

    public updateNetwork(form: INetworkInstance): Observable<INetworkInstance> {
        return this.api.post<INetworkInstance, INetworkInstance>(`networks/${form.id}`, {
            network: form
        });
    }

    public uploadNetworkLogo(networkId: string, logoFile: File) {
        return this.api.upload<INetworkThemeInstance>(
            `networks/${networkId}/logo`,
            logoFile,
            {
                method: "PUT"
            },
            "logoFile"
        );
    }

    public deleteNetwork(networkId: string): Observable<boolean> {
        return this.api.delete(`networks/${networkId}`);
    }

    public addNetworkIdToQueryParams(query: object): object {
        if (this.currentNetworkContext.contextType === NetworkContextType.Network) {
            const networkId = this.currentNetworkContext.network?.id;
            if (networkId) {
                return { ...query, networkId };
            } else {
                return query;
            }
        } else {
            return query;
        }
    }

    public getNetworkIdFromContext(context: INetworkContext): string | null {
        if (context.contextType === NetworkContextType.Network) {
            const networkId = context.network?.id;
            if (networkId) {
                return networkId;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    public updateNetworkAbility(network: INetworkInstance | null) {
        const ability = defineAbilityForNetwork(network, this.ability);
        return this.ability.update(ability.rules);
    }

    public getNetworkData<T>(networkId: string, scope: string, property: string): Observable<T | null> {
        return this.api
            .get<{ data: T | null }>(`networks/${networkId}/data/${scope}/${property}`)
            .pipe(map((res) => res.data));
    }

    public updateNetworkData<T>(networkId: string, scope: string, property: string, data: T): Observable<T> {
        return this.api
            .put<{ data: T }, { data: T }>(`networks/${networkId}/data/${scope}/${property}`, {
                data
            })
            .pipe(map((res) => res.data));
    }
}
