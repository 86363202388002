import { AbilityBuilder } from "@casl/ability";
import { AppAbility, CRUD } from ".";
import { UserRole } from "../enums/UserRole.enum";
import { IUserInstance } from "../interfaces/user.interface";

export type ContentSubjects = "Content";

export default function defineContentAbility(builder: AbilityBuilder<AppAbility>, user: IUserInstance | null) {
    const { can, cannot } = builder;
    if (user) {
        if (user.role === UserRole.SuperAdmin) {
            can([...CRUD, "list"], "Content");
        } else {
            can(["list", "read"], "Content");

            if (user.role === UserRole.NetworkAdmin) {
                can([...CRUD, "list"], "Content");
            } else if (user.role === UserRole.GroupAdmin) {
                can([...CRUD, "list"], "Content");
            } else if (user.role === UserRole.LocationAdmin) {
                can([...CRUD, "list"], "Content");
            }
        }
    }

    return builder;
}
