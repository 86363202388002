import { AbilityBuilder } from "@casl/ability";
import { AppAbility, CRUD } from ".";
import { UserRole } from "../enums/UserRole.enum";
import { IUserInstance } from "../interfaces/user.interface";

export type UserSubjects = "User" | IUserInstance;

export default function defineUserAbility(builder: AbilityBuilder<AppAbility>, user: IUserInstance | null) {
    const { can, cannot, build } = builder;

    if (user) {
        if (user.role === UserRole.SuperAdmin) {
            can([...CRUD, "list"], "User");
        } else if (user.role === UserRole.NetworkAdmin) {
            can([...CRUD, "list"], "User", {
                networkIds: {
                    $in: user.networkIds
                }
            });
            cannot(CRUD, "User", {
                networkIds: {
                    $size: 0
                }
            });

            cannot(["create", "update", "delete"], "User", {
                role: UserRole.SuperAdmin
            }).because("You cannot manage users with a SuperAdmin role");
        }
    }

    return builder;
}
