import { AbilityBuilder } from "@casl/ability";
import { AppAbility, CRUD } from ".";
import { UserRole } from "../enums/UserRole.enum";
import { IDeviceInstance } from "../interfaces/device.interface";
import { IUserInstance } from "../interfaces/user.interface";

export type DeviceSubjects = "Device" | IDeviceInstance;
type Device = IDeviceInstance;
type FlatDevice = Device & {
    "location.networkGroupId": string;
    "location.id": string;
};

export default function defineDeviceAbility(builder: AbilityBuilder<AppAbility>, user: IUserInstance | null) {
    const { can, cannot } = builder;
    if (user) {
        if (user.role === UserRole.SuperAdmin) {
            can([...CRUD, "list"], "Device");
        } else {
            can("read", "Device", {
                networkId: {
                    $in: user.networkIds
                },
                location: {
                    $exists: true
                },
                "location.networkGroupId": {
                    $exists: false
                },
                "location.id": {
                    $in: user.locationIds
                }
            });

            can("read", "Device", {
                networkId: {
                    $in: user.networkIds
                },
                location: {
                    $exists: true
                },
                "location.networkGroupId": {
                    $exists: true,
                    $in: user.networkGroupIds
                }
            });

            if (user.role === UserRole.NetworkAdmin) {
                can(["read", "list"], "Device", {
                    networkId: {
                        $in: user.networkIds
                    }
                });
            } else if (user.role === UserRole.GroupAdmin) {
                can(["read", "list"], "Device", {
                    location: {
                        $exists: true
                    },
                    "location.networkGroupId": {
                        $exists: true,
                        $in: user.networkGroupIds
                    }
                });
            } else if (user.role === UserRole.LocationAdmin) {
                can(["read", "list"], "Device", {
                    location: {
                        $exists: true
                    },
                    "location.id": {
                        $in: user.locationIds
                    }
                });
            }
        }
    }

    return builder;
}
