import { HttpErrorResponse } from "@angular/common/http";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

// JSON Override
fetch("environment.json")
    .then((res) => res.json())
    .then((json) => {
        environment.version = json.version;
        environment.buildNumber = json.buildNumber !== "{{buildNumber}}" ? json.buildNumber : "LOCAL";
        environment.commitHash = json.commitHash !== "{{commitHash}}" ? json.commitHash : "LOCAL";
        console.info(`**** Version - ${environment.version} ****`);
        console.info(`**** Build - ${environment.buildNumber} ****`);
        console.info(`**** Commit - ${environment.commitHash} ****`);
        console.info(`**** Environment - ${environment.name} ****`);
    })
    .catch((err) => {
        console.error("Failed to fetch environment.json");
    })
    .finally(() => {
        if (environment.production) {
            enableProdMode();
        }

        Sentry.init({
            dsn: "https://c15c4763bee247ec9614b4d28a224467@o1073115.ingest.sentry.io/6072840",
            integrations: [
                new Integrations.BrowserTracing({
                    routingInstrumentation: Sentry.routingInstrumentation
                })
            ],
            environment: environment.name,
            release: `snow-owl-admin@${environment.version}+${environment.buildNumber}`,
            beforeSend: (event: Sentry.Event, hint?: any) => {
                if (window.location.hostname === "localhost" && !environment.enableLocalSentryCapture) {
                    return null;
                }

                // TODO: Update to use proper error detection
                if (hint && hint.originalException instanceof HttpErrorResponse) {
                    return null;
                }

                return event;
            },

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            tracesSampler: (samplingContext) => {
                if (window.location.hostname === "localhost" && !environment.enableLocalSentryCapture) {
                    return 0;
                }

                const url = samplingContext.location?.href || "";
                if (url.includes("health")) {
                    // Drop this transaction, by setting its sample rate to 0%
                    return 0;
                } else {
                    // Default sample rate for all others (replaces tracesSampleRate)
                    return environment.tracesSampleRate;
                }
            }
        });

        platformBrowserDynamic()
            .bootstrapModule(AppModule)
            .catch((err) => console.error(err));
    });
