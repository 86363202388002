import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class StorageService {
    constructor() {}

    public set<T>(key: string, data: T, json?: boolean): void {
        const value = json ? JSON.stringify(data) : data;
        try {
            localStorage.setItem(key, value as string);
        } catch (err) {
            console.error(err);
        }
    }

    public get<T>(key: string, json?: boolean): T | string | null {
        try {
            const data = localStorage.getItem(key);
            if (data) {
                if (json) {
                    return JSON.parse(data) as T;
                } else {
                    return data as string;
                }
            } else {
                throw new Error("No Data for key: " + key);
            }
        } catch (err) {
            return null;
        }
    }

    public remove(key: string): void {
        try {
            localStorage.removeItem(key);
        } catch (err) {}
    }

    public setSession<T>(key: string, data: T, json?: boolean): void {
        const value = json ? JSON.stringify(data) : data;
        try {
            sessionStorage.setItem(key, value as string);
        } catch (err) {
            console.error(err);
        }
    }

    public getSession<T>(key: string, json?: boolean): T | string | null {
        try {
            const data = sessionStorage.getItem(key);
            if (data) {
                if (json) {
                    return JSON.parse(data) as T;
                } else {
                    return data as string;
                }
            } else {
                throw new Error("No Data for key: " + key);
            }
        } catch (err) {
            return null;
        }
    }

    public removeSession(key: string): void {
        try {
            sessionStorage.removeItem(key);
        } catch (err) {}
    }
}
