import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AppAbility, defineAbilityForUser } from "../abilities";
import { LocalStorageKeys } from "../enums/LocalStorageKeys.enum";
import { UserRole } from "../enums/UserRole.enum";
import { IDecodedToken } from "../interfaces/api.interface";
import { IAuthResponse } from "../interfaces/auth.interface";
import { IUserInstance } from "../interfaces/user.interface";
import { AppStateService } from "./app-state.service";
import { LocationService } from "./location.service";
import { NetworksService } from "./networks.service";
import { StorageService } from "./storage.service";

@Injectable({
    providedIn: "root"
})
export class AuthService {
    private baseUrl: string;

    public user$ = new BehaviorSubject<IUserInstance | null>(null);
    public token$ = new BehaviorSubject<string | null>(null);
    public rememberMe: boolean = false;

    constructor(
        private http: HttpClient,
        private appState: AppStateService,
        private storageService: StorageService,
        private router: Router,
        private ability: AppAbility,
        private networksService: NetworksService,
        private locationService: LocationService
    ) {
        this.baseUrl = environment.baseUrl;

        this.updateAbility(null);

        const isLoggedIn = this.checkAuthState();
        if (isLoggedIn) {
            setTimeout(() => {
                this.getProfile();
            }, 0);
        }
    }

    public login(email: string, password: string, rememberMe: boolean = true) {
        this.appState.isLoading = true;
        const body = { email, password };
        return this.http.post<IAuthResponse>(`${this.baseUrl}/auth/login`, body).pipe(
            map((res) => {
                if (!res || !res.token || !res.user) {
                    console.error("Invalid auth response");
                    throw new Error("Authentication Error");
                }

                if (res.user.role < UserRole.Standard) {
                    throw new Error("Unauthorized");
                }
                return { ...res, success: true };
            }),
            catchError((err) =>
                of({
                    error: err.error,
                    success: false,
                    user: null,
                    token: null
                })
            ),
            tap((res) => {
                if (res.success) {
                    // Persist data
                    this.setUser(res.user, rememberMe);
                    this.setToken(res.token, rememberMe);
                }
            })
        );
    }

    public async getProfile() {
        try {
            this.appState.isLoading = true;
            const user = await this.http.get<IUserInstance>(`${this.baseUrl}/auth/profile`).toPromise();

            if (!user || !user.id) {
                console.error("Invalid profile response");
                throw new Error("Authentication Error");
            }

            // Persist data
            this.setUser(user, this.rememberMe);
            this.appState.isLoading = false;
            return {
                success: true
            };
        } catch (err) {
            this.appState.isLoading = false;
            this.logout();
            return {
                error: err,
                success: false
            };
        }
    }

    private updateAbility(user: IUserInstance | null) {
        // TODO: Remove override
        // const devRoleOverride: UserRole = UserRole.NetworkAdmin;
        // if (user) {
        //     user.role = devRoleOverride;
        // }

        const abilityBuilder = defineAbilityForUser(user, this.ability);
        this.ability.update(abilityBuilder.rules);
    }

    public resetPasswordInit(email: string): Observable<{ success: boolean; error?: string }> {
        return this.http
            .get<void>(`${this.baseUrl}/auth/reset-password/send`, {
                params: {
                    email: email.toLowerCase()
                }
            })
            .pipe(
                map(() => ({ success: true })),
                catchError((err: HttpErrorResponse) => {
                    console.error(err);

                    return of({
                        success: false,
                        error: err.error ? err.error.message : err.message
                    });
                })
            );
    }

    public resetPassword(
        email: string,
        password: string,
        token: string
    ): Observable<{ success: boolean; error?: string }> {
        const body = {
            email,
            password,
            token
        };
        return this.http.post<{ success: boolean }>(`${this.baseUrl}/auth/reset-password/confirm`, body).pipe(
            catchError((err: HttpErrorResponse) => {
                console.error(err);

                return of({
                    success: false,
                    error: err.error ? err.error.message : err.message
                });
            })
        );
    }

    public logout(): void {
        this.setUser(null);
        this.setToken(null);
        this.ability.update([]);
        this.networksService.clearCurrentNetworkContext();
        this.locationService.clearCurrentLocation();
        // Clear network context on logout
        this.storageService.removeSession(LocalStorageKeys.Network);
        this.router.navigate(["/auth", "login"]);
    }

    public checkAuthState() {
        let user = this.storageService.getSession<IUserInstance>(LocalStorageKeys.User, true);
        if (!user) {
            user = this.storageService.get<IUserInstance>(LocalStorageKeys.User, true);
        }

        let token = this.storageService.getSession<string>(LocalStorageKeys.Token);
        if (!token) {
            token = this.storageService.get<string>(LocalStorageKeys.Token);
        }

        if (token && user) {
            this.setUser(user as IUserInstance);
            this.setToken(token);
            if (this.checkTokenExpiration()) {
                this.setToken(null);
                this.setUser(null);
                return false;
            }
            return true;
        } else {
            this.setToken(null);
            this.setUser(null);
            return false;
        }
    }

    /**
     * Gets metadata from a token
     */
    public decodeToken(token: string | null): IDecodedToken | null {
        try {
            if (!token) {
                throw new Error("Missing token");
            }
            const base64Url = token.split(".")[1];
            const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split("")
                    .map((c) => {
                        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join("")
            );

            return JSON.parse(jsonPayload);
        } catch (err) {
            console.error(err);
            return null;
        }
    }

    /**
     * Checks if the token session has expired
     */
    public checkTokenExpiration() {
        const decodedToken = this.decodeToken(this.token);
        if (decodedToken) {
            const expiresIn = decodedToken.exp * 1000;
            const now = Date.now();
            if (now > expiresIn) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    private setUser(user: IUserInstance | null, persist?: boolean) {
        this.updateAbility(user);

        Sentry.configureScope((scope) => {
            scope.setUser(user);
        });

        if (user) {
            if (persist) {
                this.storageService.set(LocalStorageKeys.User, user, true);
            }
            this.storageService.setSession(LocalStorageKeys.User, user, true);
        } else {
            this.storageService.remove(LocalStorageKeys.User);
            this.storageService.removeSession(LocalStorageKeys.User);
        }
        this.user$.next(user);
    }

    public get user(): IUserInstance | null {
        return this.user$.getValue();
    }

    public setToken(token: string | null, persist?: boolean) {
        this.token$.next(token);
        if (token) {
            if (persist) {
                this.storageService.set(LocalStorageKeys.Token, token);
            }
            this.storageService.setSession(LocalStorageKeys.Token, token);
        } else {
            this.storageService.remove(LocalStorageKeys.Token);
            this.storageService.removeSession(LocalStorageKeys.Token);
        }
    }

    public get token(): string | null {
        return this.token$.getValue();
    }

    public get isLoggedIn(): boolean {
        return !!this.user;
    }
}
