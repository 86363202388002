import { AbilityBuilder } from "@casl/ability";
import { AppAbility, CRUD } from ".";
import { UserRole } from "../enums/UserRole.enum";
import { IPresentationInstance } from "../interfaces/presentation.interface";
import { IUserInstance } from "../interfaces/user.interface";

export type PresentationSubjects = "Presentation" | IPresentationInstance;

export default function definePresentationAbility(builder: AbilityBuilder<AppAbility>, user: IUserInstance | null) {
    const { can, cannot, build } = builder;

    if (user) {
        if (user.role === UserRole.SuperAdmin) {
            can([...CRUD, "list"], "Presentation");
        }
    }

    return builder;
}
