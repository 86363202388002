import { AbilityBuilder } from "@casl/ability";
import { AppAbility, CRUD } from ".";
import { UserRole } from "../enums/UserRole.enum";
import { IServiceInstance } from "../interfaces/pricing.interface";
import { IUserInstance } from "../interfaces/user.interface";

export type ServiceSubjects = "Service" | IServiceInstance;

export default function defineServiceAbility(builder: AbilityBuilder<AppAbility>, user: IUserInstance | null) {
    const { can, cannot, build } = builder;

    if (user) {
        if (user.role === UserRole.SuperAdmin) {
            can([...CRUD, "list"], "Service");
        } else {
            if (user.role === UserRole.NetworkAdmin) {
                can([...CRUD, "list"], "Service", {
                    "category.networkId": {
                        $in: user.networkIds
                    }
                });
            } else if (user.role === UserRole.GroupAdmin || user.role === UserRole.LocationAdmin) {
                can(["read", "list"], "Service", {
                    "category.networkId": {
                        $in: user.networkIds
                    }
                });
            }
        }
    }

    return builder;
}
