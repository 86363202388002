import { HttpClientModule, HttpErrorResponse, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import { Ability, PureAbility } from "@casl/ability";
import { AbilityModule } from "@casl/angular";
import * as Sentry from "@sentry/angular";
import { AppAbility } from "src/shared/abilities";
import { AuthInterceptor } from "src/shared/interceptors/auth.interceptor";
import { HttpErrorInterceptor } from "src/shared/interceptors/error.interceptor";
import { LoaderInterceptor } from "src/shared/interceptors/loading.interceptor";
import { SentryInterceptor } from "src/shared/interceptors/sentry.interceptor";
import { MultiAblePipe } from "src/shared/pipes/multi-able.pipe";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatSnackBarModule,
        AbilityModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SentryInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        { provide: Ability, useValue: new AppAbility() },
        { provide: PureAbility, useExisting: Ability },
        MultiAblePipe,
        Title,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
                logErrors: true,
                extractor: (error, defaultExtractor) => {
                    if (error instanceof HttpErrorResponse) {
                        return error;
                    } else {
                        return defaultExtractor(error);
                    }
                }
            })
        },
        {
            provide: Sentry.TraceService,
            deps: [Router]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
