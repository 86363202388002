import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

/**
 * This provider appends a token to all API requests, when applicable
 */
@Injectable()
export class SentryInterceptor implements HttpInterceptor {
    constructor() {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Only trace api requests
        if (!req.url.includes(environment.baseUrl)) {
            return next.handle(req);
        }
        const transaction = Sentry.getActiveTransaction() || Sentry.startTransaction({ name: req.url });
        const span = transaction.startChild({
            op: "http",
            description: `${req.method} ${req.url}`
        });

        req = this.addTraceId(req, span.traceId);

        return next.handle(req).pipe(
            tap((event) => {
                if (event.type == HttpEventType.Response) {
                    span.setHttpStatus(event.status);
                    span.finish();
                }
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }

    private addTraceId(req: HttpRequest<any>, traceId: string) {
        const headers = req.headers.set("sentry-trace", traceId);
        return req.clone({
            headers
        });
    }
}
