import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { ThemeService } from "src/shared/services/theme.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
    constructor(
        private titleService: Title,
        private router: Router,
        private route: ActivatedRoute,
        private themeService: ThemeService
    ) {}

    ngOnInit(): void {
        this.initDynamicTitles();
    }

    private initDynamicTitles() {
        // Custom page titles based on route data
        const getChildTitle = (route: ActivatedRouteSnapshot): string | undefined => {
            if (route.firstChild) {
                return getChildTitle(route.firstChild) || route.data?.title;
            }
            return route.data?.title;
        };

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event) => {
                    const title = getChildTitle(this.route.snapshot);
                    if (title) {
                        return `${title} - ${this.themeService.appTitle}`;
                    }
                    return this.themeService.appTitle;
                })
            )
            .subscribe((title: string) => {
                this.titleService.setTitle(title);
            });
    }
}
