export enum FeatureSlug {
    SMC = "smc",
    VideoPlaylistGlobalContent = "video-playlist",
    VideoPlaylistLocationContent = "video-playlist-location-content",
    GreetingPlaylist = "greeting-playlist",
    TireProsServices = "tirepros-services",
    TireProsTirewall = "tirepros-tirewall",
    TireProsWorkflow = "tirepros-workflow",
    MichelinTirewall = "michelin-tirewall"
}
