import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "../services/auth.service";

/**
 * This provider appends a token to all API requests, when applicable
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.authService.token;
        if (token) {
            req = this.addToken(req, token);
            return next.handle(req).pipe(
                catchError((error) => {
                    if (error instanceof HttpErrorResponse && error.status === 401) {
                        return this.handle401Error(req, next);
                    } else {
                        return throwError(error);
                    }
                })
            );
        } else {
            return next.handle(req);
        }
    }

    private addToken(req: HttpRequest<any>, token: string) {
        const headers = req.headers.set("Authorization", `Bearer ${token}`);
        return req.clone({
            headers
        });
    }

    private handle401Error(res: HttpRequest<any>, next: HttpHandler) {
        this.authService.logout();
        return next.handle(res);
    }
}
